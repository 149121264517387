import React from 'react';
import {
  Link
} from 'gatsby';
import Img from 'gatsby-image';

export default class ProjectCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
    }
    this.setActive = this.setActive.bind(this);
    this.setInactive = this.setInactive.bind(this);
  }

  setActive() {
    this.setState({
      active: true,
    })
  }

  setInactive() {
    this.setState({
      active: false,
    })
  }

  render() {
    return ( 
      <Link 
        className="project-card"
        to={this.props.slug}
        onMouseEnter={this.setActive}
        onMouseLeave={this.setInactive}
        style={{cursor: this.props.isActive ? 'normal' : 'pointer'}}
      > 
        <div style={{
          height: 0, 
          position: 'relative', 
          top: 18,
          transform: 'translateY(-50%)', 
          zIndex: 1,
          color: '#FFF',
          marginTop: 'auto',
        }}>
          {this.props.isActive && (
            <h4 style={{margin: 18, fontWeight: 'bold', fontVariantCaps: 'all-small-caps', color: this.props.isActive ? '#0af' : '#FFF'}}>Currently Viewing:</h4>  
          )}
          <h3 className={this.props.isActive ? 'post-title' :''} style={{color: this.props.isActive ? '#0af' : '#FFF', margin: 18}}>{this.props.title}</h3>
          <h4 style={{margin: 18, fontWeight: 'bold', fontVariantCaps: 'all-small-caps', color: this.props.isActive ? '#0af' : '#FFF'}}>{this.props.metaDesc}</h4>
        </div>
        <Img 
          fluid={this.props.metaImg} 
          className='project-card-img'
          style={this.props.isActive ? {
            transition: 'filter 0.3s',
            filter: 'brightness(0.1) saturate(0.1)'
          } : {
            transition: 'filter 0.3s',
            filter: this.state.active ? `brightness(0.5) sepia(0) hue-rotate(0turn) saturate(0.5)` : `brightness(0.5) sepia(1) hue-rotate(${((((Math.random() * 3) / 18) + (Math.random()*2)) / 5) + 0.21}turn) saturate(1.8)`,
          }}
        />
      </Link>
    );
  }
}