import React from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'
import ProjectCard from './ProjectCard';


class ProjectRoll extends React.Component {

  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark
    return (
      <div className="columns is-multiline">
        {posts && (posts.map((post, key) => (
          <ProjectCard
            key={key} 
            title={post.node.frontmatter.title} 
            metaDesc={post.node.frontmatter.metaDesc}
            metaImg={post.node.frontmatter.metaImg.childImageSharp.fluid}
            hoverImg={post.node.frontmatter.hoverImg.childImageSharp.fluid}
            slug={post.node.fields.slug}
            isActive={this.props.current === post.node.fields.slug}
          />
        )))}
      </div>
    );
  }
}

ProjectRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default ({current}) => (
  <StaticQuery
    query={graphql`
    query ProjectRollQuery {
      allMarkdownRemark(
        sort: { order: DESC, fields: [frontmatter___date] },
        filter: { frontmatter: { templateKey: { eq: "project-post" } }}
      ) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              title
              date
              templateKey
              metaDesc
              metaImg {
                childImageSharp {
                  fluid(maxWidth: 400, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              hoverImg {
                childImageSharp {
                  fluid(maxWidth: 400, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
    `}
    render={(data, count) => (
      <ProjectRoll data={data} count={count} current={current} />
    )}
  />
)
